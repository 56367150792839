import {mapActions, mapGetters} from "vuex";
import ApprovePopup from '@/components/popups/approve-popup.vue';
import TransactionSuccessPopup from '@/components/popups/transaction-success-popup';
import SellPricePopup from '@/components/popups/sell-price-popup';
import ApiShop from "@/service/ApiShop";
import BoostersMixin from "@/mixins/boostersMixin";

export default {
    mixins: [BoostersMixin],
    components: {
        ApprovePopup,
        TransactionSuccessPopup,
        SellPricePopup,
    },
    data() {
        return {
            isProcessing: false,
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'GET_IS_DEVELOPMENT']),
        ...mapGetters('contract', ['balance']),
    },
    methods: {
        ...mapActions('contract', [
            'allowance',
            'approve',
            'getBalance',
            'sell',
            'buy',
            'buyBooster',
            'cancelListing',
        ]),

        async onBuyNftClick(character) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            const allowance = await this.getAllowance();

            if (allowance === 'error') {
                return;
            }

            if (!this.enoughBalance(character.price, 'You don\'t have enough money to buy this NFT')) {
                return;
            }

            if (this.fromWei(allowance) < Number(character.price)) {
                this.$refs.approvePopup.open(character.price, character.nft.mf_attributes.token_id);
            } else {
                this.buyCharacter(character.nft.mf_attributes.token_id);
            }
        },
        async buyCharacter(token_id) {
            this.isProcessing = true;

            try {
                const result = await this.buy(token_id);

                await this.getBalance();

                this.$refs.transactionSuccessPopup.open(
                    result.transactionHash,
                    {
                        title: 'Congratulations on your new fighter',
                        text: `Find your new Fighter in 
                                <a class="hash" href="` + window.location.origin + `/marketplace/my_nfts">MY NFT</a> Section.  
                              `,
                        isHtml: true,
                    },
                )
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async buyBoosterHandler(boosterId) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.isProcessing = true;

            try {
                const vrsData = await ApiShop.buyBoosterGetVrs(boosterId);
                const result = await this.buyBooster(vrsData.data);

                await this.getBalance();

                this.$refs.transactionSuccessPopup.open(result.transactionHash)
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        onSellNftClick(token_id) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.$refs.sellPricePopup.open(token_id, this.sellNft);
        },
        async sellNft(token_id, price) {
            if (!price) {
                console.error('The selling price is empty')
                return;
            }

            this.isProcessing = true;

            try {
                const result = await this.sell({
                    token_id,
                    price: this.web3ToWei(price),
                });

                this.$refs.transactionSuccessPopup.open(
                    result.transactionHash,
                    {
                        title: 'Listing completed',
                        text: `Your Fighter has been listed on the 
                                <a class="hash" href="` + window.location.origin + `/marketplace/shop">Marketplace</a>.  
                               You can cancel your listing in your <a class="hash" href="` + window.location.origin + `/marketplace/my_nfts">My NFT</a> section.
                              `,
                        isHtml: true,
                    },
                )
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onCancelListingClick(token_id) {
            this.isProcessing = true;

            try {
                const result = await this.cancelListing(token_id);

                this.$refs.transactionSuccessPopup.open(
                    result.transactionHash,
                    {
                        title: 'YOUR LISTING HAS BEEN CANCELLED',
                        text: ' ',
                    },
                )
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onBuyClick(element) {
            this.isProcessing = true;

            try {
                const result = await this.buy(element.token_id);

                this.$refs.transactionSuccessPopup.open(result.transactionHash)
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async getAllowance() {
            this.isProcessing = true;

            let allowance;

            try {
                allowance = await this.allowance();
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;

            return allowance;
        },
        enoughBalance(price, text = '') {
            if (!this.balance || Number(price) > Number(this.balance)) {
                this.$eventBus.$emit('errorPopup', '', text || 'You don\'t have enough money for this transaction');

                return false;
            }

            return true;
        },
    },
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "sell-price-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", { staticClass: "sell-price-popup__content" }, [
        _c("h3", { staticClass: "sell-price-popup__title" }, [
          _vm._v("SELL YOUR NFT"),
        ]),
      ]),
      _c("div", { staticClass: "sell-price-popup__text" }, [
        _c("p", [
          _vm._v("Set your selling price for your Fighter NFT in MF Tokens."),
        ]),
        _c(
          "p",
          [
            _vm._v("MetaFighter will charge a transaction fee of "),
            _vm.isBalanceLoading
              ? _c("Loader", { staticClass: "fee-loader", attrs: { xs: "" } })
              : _c("span", [_vm._v(_vm._s(_vm.fee) + "%")]),
            _vm._v(" for any sold NFT Fighter. "),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "sell-price-popup__buttons" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.amount,
                expression: "amount",
              },
            ],
            staticClass:
              "sell-price-popup__buttons-item sell-price-popup__buttons-item--amount",
            attrs: { type: "text", placeholder: "enter price in MF tokens" },
            domProps: { value: _vm.amount },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.amount = $event.target.value
              },
            },
          }),
          _c("btn", {
            staticClass: "sell-price-popup__buttons-item",
            attrs: {
              type: "icon-gradient",
              text: "SELL",
              disabled: _vm.acceptDisabled,
            },
            on: { click: _vm.onConfirmClick },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <popup v-model="popup" @input="close" content-class="sell-price-popup">
        <div class="sell-price-popup__content">
            <h3 class="sell-price-popup__title">SELL YOUR NFT</h3>
        </div>

        <div class="sell-price-popup__text">
            <p>Set your selling price for your Fighter NFT in MF Tokens.</p>
            <p>MetaFighter will charge a transaction fee of
                <Loader v-if="isBalanceLoading" xs class="fee-loader"/>
                <span v-else>{{fee}}%</span>
                for any sold NFT Fighter.
            </p>
        </div>

        <div class="sell-price-popup__buttons">
            <input
                class="sell-price-popup__buttons-item sell-price-popup__buttons-item--amount"
                type="text"
                placeholder="enter price in MF tokens"
                v-model="amount"
            />

            <btn
                class="sell-price-popup__buttons-item"
                type="icon-gradient"
                text="SELL"
                @click="onConfirmClick"
                :disabled="acceptDisabled"
            />
        </div>
    </popup>
</template>

<script>
import {mapGetters} from 'vuex';
import Loader from '@/components/common/loader.vue'

export default {
    name: "SellPricePopup",
    components: {
        Loader,
    },
    computed: {
        ...mapGetters('contract', ['fee', 'isBalanceLoading']),

        acceptDisabled() {
            return 0 >= Number(this.amount) || this.isBalanceLoading;
        },
    },
    watch:{
        amount(newValue){
            const re = /[^\d.-]+/g;
            let amountString = String(newValue).replace(re, '')
            let formattedAmountString = Number(amountString).toLocaleString('en-US')  
            this.$nextTick(() => this.amount = formattedAmountString);
        }
    },
    data() {
        return {
            amount: null,
            popup: false,
            data: null,
            callback: null,
        }
    },
    methods: {
        open(data, callback){
            this.popup = true;
            this.amount = null;
            this.data = data;
            this.callback = callback;
        },
        close() {
            this.$emit('close');
            this.popup = false;
            this.amount = null;
            this.data = null;
            this.callback = null;
        },
        async onConfirmClick() {
            let amount = Number(this.amount.replaceAll(',', ''))
            this.callback(this.data, amount);
            this.close();
        },
    }
}
</script>

<style lang="scss">

.sell-price-popup {
    width: 100%;
    max-width: 645px;
    flex-direction: column;
    background-color: #C6CCD7!important;
    padding: 2em 3em!important;
    border-radius: 15px;

    .popup__close-btn {
        background-color: transparent;

        .close-btn__element {
            background-color: black;
            height: 6px;
            width: 25px;
        }
    }

    .fee-loader {
        display: inline-flex;
        justify-content: center;
        width: 30px;
        height: 7px;
        align-items: center;

        .loader__circle--xs {
            border-color: black;
        }
    }
}

.sell-price-popup__title {
    text-align: center;
    text-transform: uppercase;
    font: 800 48px Raleway;
}

.sell-price-popup__text {
    margin: 0.5em 0;
    text-align: center;
    font: 400 16px Tektur;

    &--amount {
        font: 800 24px Raleway;
    }
}

.sell-price-popup__content {
    padding: 20px;
}

.sell-price-popup__radios {
    margin-top: 15px;
    background-color: #3B3D41;
    color: white;
    padding: 40px 72px;
    display: flex;
    gap: 2em;

    @media screen and (max-width: $bp_mb) {
        flex-direction: column;
        padding: 20px 35px;

        .list-separator--arrow {
            display: none;
        }
    }
}

.sell-price-popup__radios-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 600 18px Tektur;
    gap: 1em;
}

.sell-price-popup__buttons {
    display: flex;
    padding: 10px 20px;
}

.sell-price-popup__buttons-item {
    width: 50%;

    &--amount {
        padding: 8px;
        background: transparent;
        border: 2px solid black;
        border-right: none;
        font: 500 14px Tektur;

        &:disabled {
            border-color: gray;
        }
    }
}

@media screen and (max-width: $bp_mb) {
    .sell-price-popup {
        padding: 0.6em 1em!important;
    }

    .sell-price-popup__title {
        font-size: 24px;
    }

    .sell-price-popup__text {
        font-size: 12px;
    }
}
</style>
